<template>
	<div>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-spacer></v-spacer>
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="filteredBrands"
									  :loading="listStatus === status.RUNNING" :items-per-page="appliedFilters.limit"
									  :page.sync="page" disable-filtering disable-sort hide-default-footer
									  loading-text="Loading... Please wait">
							<template v-slot:top>
								<v-toolbar id="brands-toolbar" class="rounded-t" flat>
									<v-toolbar-title>
										Brands
									</v-toolbar-title>

									<v-spacer></v-spacer>

									<v-btn @click="createDialog = true" class="mr-2" color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<v-menu v-model="filterMenu" :close-on-click="false" :close-on-content-click="false"
											left max-width="350px" offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" class="mr-2" color="primary">
												<v-icon>mdi-filter</v-icon>
											</v-btn>
										</template>
										<v-card outlined>
											<v-container fluid>
												<v-row dense>
													<v-col cols="12">
														<v-select v-model="filters.limit" :items="[10, 20, 30]" dense
																  hide-details label="Items Per Page"
																  outlined></v-select>
													</v-col>
													<v-col cols="12">
														<v-select v-model="filters.brandGroup" :items="brandGroups"
																  clearable dense hide-details item-text="name"
																  item-value="id" label="Group" outlined></v-select>
													</v-col>
												</v-row>
											</v-container>
											<v-card-actions>
												<v-btn @click="closeFilters" text>
													Cancel
												</v-btn>
												<v-spacer></v-spacer>
												<v-btn @click="applyFilters" color="primary" text>
													Apply
												</v-btn>
											</v-card-actions>
										</v-card>
									</v-menu>

									<v-btn @click="refreshData" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>

								</v-toolbar>
							</template>


							<template v-slot:item.name="props">
								<span @click="$router.push({name: 'Edit Brand', params: { id: props.item.id }})"
									  class="text-decoration-underline clickable-text" v-text="props.item.name"></span>
							</template>

							<template v-slot:item.brandGroup="props">
								{{ brandGroupForID(props.item.brandGroupId) }}
							</template>

							<template v-slot:item.active="props">
								<v-icon>
									{{ props.item.active ? 'mdi-check' : 'mdi-close' }}
								</v-icon>
							</template>

							<template v-slot:item.featured="props">
								<v-icon>
									{{ props.item.featured ? 'mdi-check' : 'mdi-close' }}
								</v-icon>
							</template>

							<template v-slot:item.actions="props">
								<v-menu right>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" icon small>
											<v-icon small>mdi-dots-vertical</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item
											@click="$router.push({name: 'Edit Brand', params: { id: props.item.id }})">
											Edit
										</v-list-item>
										<v-list-item @click="">Archive</v-list-item>
									</v-list>
								</v-menu>
							</template>
						</v-data-table>

						<v-pagination v-model="page" :length="Math.ceil(filteredBrands.length/appliedFilters.limit)"
									  :total-visible="7" class="my-4"></v-pagination>
					</v-card>
				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>

		<card-dialog :showing="createDialog" @cancel="createDialog = false" @confirmed="createBrand(brand)"
					 :confirm-disabled="createStatus === status.RUNNING" confirm-text="Create" max-width="800px"
					 title="Create Brand">
			<template v-slot:dialog-body>
				<v-row dense>
					<v-col cols="12" md="6">
						<v-text-field v-model="brand.name" autocomplete="new-password" counter="200" dense label="Name"
									  outlined></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-select v-model="brand.brandGroupId" :items="brandGroups" dense item-text="name"
								  item-value="id" label="Group" outlined></v-select>
					</v-col>
					<v-col cols="12">
						<v-textarea v-model="brand.details" auto-grow autocomplete="new-password" counter="2000" dense
									label="Description" outlined></v-textarea>
					</v-col>
				</v-row>
			</template>
		</card-dialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CardDialog from '@/components/global/CardDialog'

export default {
	name: 'BrandsIndex',
	components: {CardDialog},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '20%'},
			{text: 'Slug', value: 'slug', width: '20%'},
			{text: 'Group', value: 'brandGroup', width: '20%'},
			{text: 'Active', value: 'active', align: 'center', width: '10%'},
			{text: 'Featured', value: 'featured', align: 'center', width: '10%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '10%'},
			{text: 'Actions', value: 'actions', align: 'right', width: '10%'},
		],
		createDialog: false,
		page: 1,
		filterMenu: false,
		filters: {
			limit: 10,
			brandGroup: null,
		},
		appliedFilters: {
			limit: 10,
			brandGroup: null,
		},
		brand: {
			name: '',
			brandGroupId: null,
			details: '',
		}
	}),
	created() {
		if (this.brands.length === 0) {
			this.listBrands()
		}
		if (this.brandGroups.length === 0) {
			this.listBrandGroups()
		}
	},
	methods: {
		...mapActions('appliance/brand', [
			'listBrands',
			'createBrand',
		]),
		...mapActions('appliance/brandGroup', [
			'listBrandGroups'
		]),
		applyFilters() {
			this.appliedFilters = {...this.filters}
			this.filterMenu = false
		},
		closeFilters() {
			this.filters = {...this.appliedFilters}
			this.filterMenu = false
		},
		refreshData() {
			this.listBrandGroups()
			this.listBrands()
		},
		brandGroupForID(id) {
			for (let i = 0; i < this.brandGroups.length; i++) {
				if (this.brandGroups[i].id === id) {
					return this.brandGroups[i].name
				}
			}
		}
	},
	computed: {
		...mapGetters('appliance/brand', [
			'brands',
			'listStatus',
			'createStatus',
		]),
		...mapGetters('appliance/brandGroup', [
			'brandGroups',
		]),
		filteredBrands() {
			if (!this.appliedFilters.brandGroup) return this.brands
			return this.brands.filter(({brandGroupId}) => brandGroupId === this.appliedFilters.brandGroup)
		}
	},
	watch: {
		createDialog(updated) {
			if (!updated) {
				this.brand = {
					name: '',
					brandGroupId: null,
					details: '',
				}
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.createDialog = false
				this.listBrands()
			}
		}
	}
}
</script>

<style scoped>

</style>
